import { render, staticRenderFns } from "./xuekejiaoyan.vue?vue&type=template&id=1052284c&scoped=true"
import script from "./xuekejiaoyan.vue?vue&type=script&lang=js"
export * from "./xuekejiaoyan.vue?vue&type=script&lang=js"
import style0 from "./xuekejiaoyan.vue?vue&type=style&index=0&id=1052284c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1052284c",
  null
  
)

export default component.exports